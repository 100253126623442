var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/useClipboard.ts
import { useState, useCallback, useEffect } from "react";
var useClipboard = (text, timeout = 1200) => {
  const [hasCopied, setHasCopied] = useState(false);
  const copy = useCallback(() => {
    if ("clipboard" in navigator) {
      navigator.clipboard.writeText(text).then(() => setHasCopied(true)).catch(() => setHasCopied(false));
    }
  }, [text]);
  useEffect(() => {
    let timeoutId = null;
    if (hasCopied) {
      timeoutId = window.setTimeout(() => {
        setHasCopied(false);
      }, timeout);
    }
    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }
    };
  }, [timeout, hasCopied]);
  return { copy, hasCopied };
};

// src/useDisclosure.ts
import { useState as useState2, useCallback as useCallback2 } from "react";
var useDisclosure = (defaultIsOpen = false) => {
  const [isOpen, setIsOpen] = useState2(defaultIsOpen);
  const onClose = useCallback2(() => setIsOpen(false), []);
  const onOpen = useCallback2(() => setIsOpen(true), []);
  const onToggle = useCallback2(() => setIsOpen(!isOpen), [isOpen]);
  return {
    isOpen: !!isOpen,
    onOpen,
    onClose,
    onToggle
  };
};

// src/useInterval.ts
import { useEffect as useEffect2 } from "react";
var useInterval = (callback, delay) => {
  useEffect2(() => {
    callback();
    const id = setInterval(callback, delay);
    return () => clearInterval(id);
  }, [callback, delay]);
};

// src/useQuery.ts
import { useState as useState3 } from "react";
var useQuery = () => {
  const [loading, setLoading] = useState3(false);
  const [hasLoaded, setHasLoaded] = useState3(false);
  const [error, setError] = useState3();
  const runQuery = (query) => __async(void 0, null, function* () {
    setError(null);
    setLoading(true);
    try {
      yield query();
    } catch (err) {
      if (err == null ? void 0 : err.message) {
        setError(err.message);
      } else if (err == null ? void 0 : err.code) {
        setError(err.code);
      } else {
        setError("unknown");
      }
    } finally {
      setLoading(false);
      setHasLoaded(true);
    }
  });
  return {
    error,
    loading,
    hasLoaded,
    runQuery
  };
};

// src/useYupResolver.ts
import { useCallback as useCallback3 } from "react";
var useYupResolver = (validationSchema) => {
  return useCallback3(
    (data) => __async(void 0, null, function* () {
      try {
        const values = yield validationSchema.validate(data, {
          abortEarly: false
        });
        return {
          values,
          errors: {}
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => {
              var _a;
              return __spreadProps(__spreadValues({}, allErrors), {
                [currentError.path]: {
                  type: (_a = currentError.type) != null ? _a : "validation",
                  message: currentError.message
                }
              });
            },
            {}
          )
        };
      }
    }),
    [validationSchema]
  );
};
export {
  useClipboard,
  useDisclosure,
  useInterval,
  useQuery,
  useYupResolver
};
