var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/Actions/FullDialog.tsx
import React13 from "react";
import * as RadixDialog from "@radix-ui/react-dialog";
import Icons7 from "@petrus/ui-icons";
import { twMerge as merge } from "tailwind-merge";
import { cva as cva2 } from "class-variance-authority";

// src/Layout/Banner/Banner.tsx
import { twMerge } from "tailwind-merge";
import { jsx } from "react/jsx-runtime";
var Banner = ({ children, className }) => {
  return /* @__PURE__ */ jsx(
    "div",
    {
      className: twMerge(
        "w-full p-2 bg-carmine text-white flex items-center justify-center text-center",
        className
      ),
      children
    }
  );
};

// src/Layout/Card/Card.tsx
import React2 from "react";
import { twMerge as twMerge2 } from "tailwind-merge";

// src/helpers/Clone.tsx
import React from "react";
function mergeProps(slotProps, childProps) {
  const overrideProps = __spreadValues({}, childProps);
  for (const propName in childProps) {
    if (childProps.hasOwnProperty(propName)) {
      const slotPropValue = slotProps[propName];
      const childPropValue = childProps[propName];
      if (/^on[A-Z]/.test(propName)) {
        if (slotPropValue && childPropValue) {
          overrideProps[propName] = (...args) => {
            childPropValue(...args);
            slotPropValue(...args);
          };
        } else if (slotPropValue) {
          overrideProps[propName] = slotPropValue;
        }
      } else if (propName === "className") {
        overrideProps[propName] = [slotPropValue, childPropValue].filter(Boolean).join(" ");
      }
    }
  }
  return __spreadValues(__spreadValues({}, slotProps), overrideProps);
}
var Clone = (props) => {
  const _a = props, { children } = _a, slotProps = __objRest(_a, ["children"]);
  if (React.isValidElement(children)) {
    return React.cloneElement(children, mergeProps(slotProps, children.props));
  }
  return React.Children.count(children) > 1 ? React.Children.only(null) : null;
};
Clone.displayName = "Clone";

// src/Layout/Card/Card.tsx
var Card = (_a) => {
  var _b = _a, { asChild = false, className = "", children } = _b, props = __objRest(_b, ["asChild", "className", "children"]);
  const Comp = asChild ? Clone : "div";
  return React2.createElement(
    Comp,
    __spreadValues({
      className: twMerge2("shadow overflow-hidden bg-white rounded-lg p-3", className)
    }, props),
    children
  );
};

// src/Layout/Center.tsx
import classNames from "classnames";
import React3 from "react";
var Center = (_a) => {
  var _b = _a, { children, className = "" } = _b, props = __objRest(_b, ["children", "className"]);
  return React3.createElement(
    "div",
    __spreadValues({
      className: classNames("flex justify-center", className)
    }, props),
    children
  );
};

// src/Layout/Container.tsx
import React4 from "react";
import classNames2 from "classnames";
import { twMerge as twMerge3 } from "tailwind-merge";
var sizeStyles = {
  no: "",
  xs: "max-w-screen-xs",
  sm: "max-w-screen-sm",
  md: "max-w-screen-md",
  lg: "max-w-screen-lg",
  xl: "max-w-screen-xl"
};
var Container = (_a) => {
  var _b = _a, { children, className = "", size = "xl" } = _b, props = __objRest(_b, ["children", "className", "size"]);
  return React4.createElement(
    "div",
    __spreadValues({
      className: twMerge3(classNames2("mx-auto px-3.5 lg:px-5", sizeStyles[size]), className)
    }, props),
    children
  );
};

// src/Layout/For.tsx
import { Children } from "react";
import { Fragment, jsx as jsx2 } from "react/jsx-runtime";
var For = ({ each, render, empty }) => {
  if (!each || each.length === 0) {
    if (empty) {
      return /* @__PURE__ */ jsx2(Fragment, { children: empty() });
    }
    return null;
  }
  return /* @__PURE__ */ jsx2(Fragment, { children: Children.toArray(each.map((item, index) => render(item, index))) });
};

// src/Layout/ScrollArea.tsx
import classNames3 from "classnames";
import * as RadixScrollArea from "@radix-ui/react-scroll-area";
import { jsx as jsx3, jsxs } from "react/jsx-runtime";
var theme = {
  base: "overflow-hidden w-full h-full",
  area: "w-full h-full",
  scroll: "flex p-0.5 bg-white select-none touch-none transition-colors hover:bg-grey rounded-md",
  verticalScroll: "w-3",
  horizontalScroll: "flex-col h-3",
  thumb: 'flex-1 bg-carmine rounded-lg relative before:content-[""] before:absolute before:inset-y-1/2 before:-translate-y-1/2 before:-translate-x-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]'
};
var ScrollArea = ({ children, className = "", orientation }) => /* @__PURE__ */ jsxs(
  RadixScrollArea.Root,
  {
    className: classNames3(
      theme.base,
      orientation === "vertical" && "pb-4",
      orientation === "horizontal" && "pr-4",
      className
    ),
    children: [
      /* @__PURE__ */ jsx3(RadixScrollArea.Viewport, { className: theme.area, children }),
      /* @__PURE__ */ jsx3(
        RadixScrollArea.Scrollbar,
        {
          className: classNames3(theme.horizontalScroll, theme.scroll),
          orientation: "horizontal",
          children: /* @__PURE__ */ jsx3(RadixScrollArea.Thumb, { className: theme.thumb })
        }
      ),
      /* @__PURE__ */ jsx3(
        RadixScrollArea.Scrollbar,
        {
          className: classNames3(theme.verticalScroll, theme.scroll),
          orientation: "vertical",
          children: /* @__PURE__ */ jsx3(RadixScrollArea.Thumb, { className: theme.thumb })
        }
      )
    ]
  }
);

// src/Layout/Tabs/Tabs.tsx
import { useState, useMemo as useMemo3 } from "react";
import * as RadixTabs from "@radix-ui/react-tabs";
import classNames15 from "classnames";

// src/Actions/Btn/Btn.tsx
import React11 from "react";
import { cva } from "class-variance-authority";
import { twMerge as twMerge7 } from "tailwind-merge";

// src/Typography/GhostText.tsx
import React6 from "react";
var GhostText = (_a) => {
  var _b = _a, { children } = _b, props = __objRest(_b, ["children"]);
  return React6.createElement(
    "span",
    __spreadProps(__spreadValues({}, props), {
      style: __spreadProps(__spreadValues({}, props.style), {
        position: "absolute",
        border: 0,
        width: 1,
        height: 1,
        padding: 0,
        margin: -1,
        overflow: "hidden",
        clip: "rect(0, 0, 0, 0)",
        whiteSpace: "nowrap",
        wordWrap: "normal"
      })
    }),
    children
  );
};

// src/Typography/Heading.tsx
import React7 from "react";
import classNames4 from "classnames";
var theme2 = {
  h1: "text-left leading-h1 text-h1",
  h2: "text-left leading-h2 text-h2",
  h3: "text-left leading-h3 text-h3",
  h4: "text-left leading-h3 text-h4"
};
var Heading = (_a) => {
  var _b = _a, { as = "h1", sized = true, className = "", children } = _b, props = __objRest(_b, ["as", "sized", "className", "children"]);
  return React7.createElement(
    as,
    __spreadValues({
      className: classNames4(sized && theme2[as], className)
    }, props),
    children
  );
};

// src/Typography/HelperText.tsx
import React9 from "react";
import classNames6 from "classnames";

// src/Typography/Text.tsx
import React8, { useMemo } from "react";
import classNames5 from "classnames";
var sizeStyle = {
  base: "text-base",
  small: "text-small",
  tiny: "text-tiny"
};
var weightStyle = {
  bold: "font-bold",
  medium: "font-medium",
  normal: "font-normal"
};
var Text = (_a) => {
  var _b = _a, {
    as = "span",
    size = "base",
    weight = "normal",
    className = "",
    children
  } = _b, props = __objRest(_b, [
    "as",
    "size",
    "weight",
    "className",
    "children"
  ]);
  const cs = useMemo(() => {
    return classNames5(weightStyle[weight], sizeStyle[size], className);
  }, [weight, size, className]);
  return React8.createElement(
    as,
    __spreadValues({
      className: cs
    }, props),
    children
  );
};

// src/Typography/HelperText.tsx
var HelperText = (_a) => {
  var _b = _a, { text, className = "" } = _b, props = __objRest(_b, ["text", "className"]);
  if (!text)
    return null;
  return React9.createElement(
    Text,
    __spreadValues({
      size: "tiny",
      className: classNames6("block my-1", className)
    }, props),
    text
  );
};

// src/Typography/Label.tsx
import React10, { useMemo as useMemo2 } from "react";
import classNames7 from "classnames";
import { Label as RadixLabel } from "@radix-ui/react-label";
var Label = (_a) => {
  var _b = _a, {
    htmlFor,
    label,
    clickable = false,
    hidden = false,
    className = "",
    required = false
  } = _b, props = __objRest(_b, [
    "htmlFor",
    "label",
    "clickable",
    "hidden",
    "className",
    "required"
  ]);
  const cs = useMemo2(() => {
    return classNames7("font-base", hidden && "hidden", clickable && "cursor-pointer", className);
  }, [hidden, clickable, className]);
  if (!label)
    return null;
  return React10.createElement(
    RadixLabel,
    __spreadValues({
      htmlFor,
      className: cs
    }, props),
    `${label}${required ? " *" : ""}`
  );
};

// src/Feedback/Badge/Badge.tsx
import { jsx as jsx4 } from "react/jsx-runtime";
var Badge = ({ text }) => {
  if (!text) {
    return null;
  }
  return /* @__PURE__ */ jsx4(Text, { size: "tiny", className: "inline-flex items-center rounded-md bg-blush px-3 py-1 text-space", children: text });
};

// src/Feedback/Empty/Empty.tsx
import { jsx as jsx5 } from "react/jsx-runtime";
var Empty = (_a) => {
  var _b = _a, { children } = _b, props = __objRest(_b, ["children"]);
  return /* @__PURE__ */ jsx5(
    "div",
    __spreadProps(__spreadValues({
      className: "relative block w-full rounded-lg border-2 border-dashed border-space/30 p-10"
    }, props), {
      children
    })
  );
};

// src/Feedback/Alert.tsx
import classNames8 from "classnames";

// src/Media/StatusIcon.tsx
import { twMerge as twMerge4 } from "tailwind-merge";
import Icons from "@petrus/ui-icons";
import { jsx as jsx6 } from "react/jsx-runtime";
var StatusIcon = (_a) => {
  var _b = _a, { status = "neutral", className = "" } = _b, restProps = __objRest(_b, ["status", "className"]);
  const props = __spreadValues({
    className: twMerge4("h-16", className)
  }, restProps);
  if (status === "success") {
    return /* @__PURE__ */ jsx6(Icons.CheckCircle, __spreadValues({}, props));
  }
  if (["warning", "danger"].includes(status)) {
    return /* @__PURE__ */ jsx6(Icons.WarningCircle, __spreadValues({}, props));
  }
  if (status === "info") {
    return /* @__PURE__ */ jsx6(Icons.InfoCircle, __spreadValues({}, props));
  }
  return /* @__PURE__ */ jsx6(Icons.QuestionCircle, __spreadValues({}, props));
};

// src/Feedback/Alert.tsx
import { jsx as jsx7, jsxs as jsxs2 } from "react/jsx-runtime";
var typeStyles = {
  success: "bg-success",
  danger: "bg-error",
  warning: "bg-warning",
  info: "bg-space",
  neutral: "bg-space"
};
var Alert = ({
  hasError = false,
  children,
  type = "neutral",
  onClose,
  className = ""
}) => {
  if (!hasError) {
    return null;
  }
  return /* @__PURE__ */ jsxs2(
    Card,
    {
      role: "alert",
      className: classNames8("flex w-full text-white p-1", typeStyles[type], className),
      children: [
        /* @__PURE__ */ jsx7("div", { className: "flex flex-shrink-0 items-center justify-center p-2", children: /* @__PURE__ */ jsx7(StatusIcon, { status: type, className: "fill-white h-6" }) }),
        /* @__PURE__ */ jsx7("div", { className: "flex flex-1 w-full items-center py-2", children }),
        onClose && /* @__PURE__ */ jsx7(
          Button,
          {
            look: "unstyled",
            size: "tiny",
            onClick: onClose,
            className: "flex flex-shrink-0 items-center justify-center",
            children: /* @__PURE__ */ jsx7("svg", { viewBox: "0 0 24 24", className: "w-6 fill-white", children: /* @__PURE__ */ jsx7("path", { d: "M19.53 5.53a.75.75 0 00-1.06-1.06l1.06 1.06zM4.47 18.47a.75.75 0 101.06 1.06l-1.06-1.06zm1.06-14a.75.75 0 00-1.06 1.06l1.06-1.06zm12.94 15.06a.75.75 0 001.06-1.06l-1.06 1.06zm0-15.06l-14 14 1.06 1.06 14-14-1.06-1.06zm-14 1.06l14 14 1.06-1.06-14-14-1.06 1.06z" }) })
          }
        )
      ]
    }
  );
};

// src/Feedback/Callout.tsx
import classNames11 from "classnames";

// src/Media/CheckboxIndicator.tsx
import classNames9 from "classnames";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import Icons2 from "@petrus/ui-icons";
import { jsx as jsx8 } from "react/jsx-runtime";
var CheckboxIndicator = (_a) => {
  var _b = _a, {
    id,
    value,
    onChange,
    className,
    disabled = false
  } = _b, props = __objRest(_b, [
    "id",
    "value",
    "onChange",
    "className",
    "disabled"
  ]);
  return /* @__PURE__ */ jsx8(
    CheckboxPrimitive.Root,
    __spreadProps(__spreadValues({}, props), {
      name: id,
      id,
      disabled,
      value,
      checked: value,
      onCheckedChange: onChange,
      className: classNames9(
        "w-6 h-6 rounded-md flex bg-white focus:outline-carmine border-1 border-space",
        value && "bg-space border-space",
        disabled && "bg-space/20 border-space/20",
        className
      ),
      children: /* @__PURE__ */ jsx8(CheckboxPrimitive.Indicator, { children: /* @__PURE__ */ jsx8(Icons2.Check, { className: "w-4 h-4 m-1 fill-white" }) })
    })
  );
};

// src/Media/GenderIcon.tsx
import { twMerge as twMerge5 } from "tailwind-merge";
import Icons3 from "@petrus/ui-icons";
import { jsx as jsx9 } from "react/jsx-runtime";
var GenderIcon = (_a) => {
  var _b = _a, { gender = "male", className = "" } = _b, restProps = __objRest(_b, ["gender", "className"]);
  const props = __spreadValues({
    className: twMerge5("h-16", className)
  }, restProps);
  if (gender === "male") {
    return /* @__PURE__ */ jsx9(Icons3.Male, __spreadValues({}, props));
  }
  return /* @__PURE__ */ jsx9(Icons3.Female, __spreadValues({}, props));
};

// src/Media/HamburgerMenu.tsx
import classNames10 from "classnames";
import { jsx as jsx10, jsxs as jsxs3 } from "react/jsx-runtime";
var theme3 = {
  base: "flex flex-col justify-between w-4 h-4 transform transition-all duration-300 origin-center overflow-hidden",
  itemBase: "bg-carmine h-[2px] transform transition-all duration-300",
  longBar: "w-7 origin-left",
  shortBar: "w-7 rounded",
  top: "rotate-[42deg]",
  middle: "-translate-x-10",
  bottom: "-rotate-[42deg]"
};
var HamburgerMenu = ({ isOpen, className }) => /* @__PURE__ */ jsxs3("div", { className: classNames10(theme3.base, className), children: [
  /* @__PURE__ */ jsx10("div", { className: classNames10(theme3.itemBase, theme3.longBar, isOpen && theme3.top) }),
  /* @__PURE__ */ jsx10("div", { className: classNames10(theme3.itemBase, theme3.shortBar, isOpen && theme3.middle) }),
  /* @__PURE__ */ jsx10("div", { className: classNames10(theme3.itemBase, theme3.longBar, isOpen && theme3.bottom) })
] });

// src/Media/Logo.tsx
import { AccessibilityWrap } from "@petrus/ui-icons";
import { jsx as jsx11, jsxs as jsxs4 } from "react/jsx-runtime";
var Logo = (_a) => {
  var _b = _a, { label = "logo" } = _b, props = __objRest(_b, ["label"]);
  return /* @__PURE__ */ jsx11(AccessibilityWrap, { label, children: /* @__PURE__ */ jsxs4("svg", __spreadProps(__spreadValues({ viewBox: "0 0 126 30", xmlns: "http://www.w3.org/2000/svg" }, props), { children: [
    /* @__PURE__ */ jsx11("path", { d: "M9.83959 17.5537V24.5579C9.83959 27.3018 10.4285 28.2299 12.7539 29.4522V29.5385H0V29.4522C1.77194 28.3111 2.11211 27.2561 2.11211 24.4717V5.06171C2.11211 2.27726 1.77194 1.22232 0 0.0811496V0H13.8557C20.5322 0 24.9239 3.20541 24.9239 8.77937C24.9239 14.3483 20.5322 17.5537 13.9013 17.5537H9.83959ZM11.5303 16.4582C15.5007 16.4582 17.3995 13.2071 17.3995 8.77937C17.3995 4.35165 15.5007 1.05494 11.5303 1.05494H9.83959V16.4582H11.5303Z" }),
    /* @__PURE__ */ jsx11("path", { d: "M44.5219 18.1418H32.7377C32.7377 23.376 35.0174 26.414 38.6069 26.414C40.8866 26.414 42.9175 25.2729 44.1005 22.3616L44.3543 22.4478C43.4252 27.048 40.2164 29.9593 35.3982 29.9593C29.9503 29.9593 25.4316 25.9931 25.4316 19.1156C25.4316 12.4918 30.1179 8.23145 35.5708 8.23145C41.6482 8.22638 44.5219 12.573 44.5219 18.1418ZM37.8048 17.2593C37.8048 12.5324 37.5103 8.94658 35.5251 8.94658C33.7075 8.94658 32.824 12.4056 32.7377 17.2593H37.8048Z" }),
    /* @__PURE__ */ jsx11("path", { d: "M60.6978 22.3213C60.19 26.5005 58.3724 29.9595 53.8994 29.9595C50.2235 29.9595 47.69 27.7634 47.69 22.9553L47.7763 9.70758H45.8774V9.20039C49.7209 8.31282 53.0566 5.57402 54.4934 1.30859H54.9148V8.65263H60.2814L60.0682 9.70758H54.9148V23.2088C54.9148 25.3593 55.5901 26.4599 57.0676 26.4599C58.672 26.4599 59.8143 25.0246 60.2357 22.2401L60.6978 22.3213Z" }),
    /* @__PURE__ */ jsx11("path", { d: "M76.6249 14.5562C76.6249 13.2071 75.8633 12.3601 74.3859 12.3601C72.5682 12.3601 70.9639 14.3838 70.9639 17.8884V25.06C70.9639 27.8445 71.6797 28.519 73.4568 29.4472V29.5334H62.1346V29.4472C63.6527 28.3922 63.7796 27.8039 63.7796 25.0144V13.3339C63.7796 10.4227 63.5258 9.78869 61.9214 8.73375V8.64753H70.9588V14.7236C71.8016 11.2646 73.5329 8.22656 76.7874 8.22656C79.3666 8.22656 81.0116 10.0423 81.0116 12.4869C81.0116 15.3982 79.0671 17.0465 76.8737 17.0465C76.1121 17.0465 75.5638 16.8335 75.3506 16.6255C76.1172 16.3314 76.6249 15.5706 76.6249 14.5562Z" }),
    /* @__PURE__ */ jsx11("path", { d: "M96.0098 13.3339C96.0098 10.4226 95.7559 9.78863 94.1515 8.73368V8.64746H103.235V25.0194C103.235 27.7633 103.488 28.4378 104.966 29.4522V29.5384H96.0098V24.5173C95.1213 28.0219 92.7553 29.9594 89.5871 29.9594C85.5762 29.9594 83.6722 27.1344 83.6722 23.2899V13.3339C83.6722 10.4226 83.4184 9.78863 81.814 8.73368V8.64746H90.8971V23.4167C90.8971 25.0194 91.5723 26.3685 93.1361 26.3685C94.7811 26.3685 96.0098 24.8064 96.0098 21.4742V13.3339Z" }),
    /* @__PURE__ */ jsx11("path", { d: "M111.383 11.4777C111.383 13.334 112.48 14.0085 115.948 15.2765C120.806 17.0466 123 18.9891 123 22.9147C123 27.2207 119.664 29.9595 115.059 29.9595C112.399 29.9595 110.749 29.0719 109.357 29.0719C108.388 29.0719 107.834 29.5385 107.286 30.0001L106.865 22.0271H106.951C109.104 26.5411 111.764 29.2444 114.932 29.2444C116.877 29.2444 118.141 28.1032 118.141 26.2469C118.141 24.2232 116.663 23.503 113.79 22.4937C109.144 20.8048 106.946 18.8217 106.946 14.8555C106.946 10.8893 110.114 8.23168 114.044 8.23168C116.496 8.23168 117.846 9.20041 119.238 9.20041C120.126 9.20041 120.715 8.82002 121.436 8.18604V15.1903H121.35C119.618 11.5182 116.912 8.98739 114.171 8.98739C112.439 8.98739 111.383 10.0018 111.383 11.4777Z" })
  ] })) });
};

// src/Media/SimpleLogo.tsx
import { AccessibilityWrap as AccessibilityWrap2 } from "@petrus/ui-icons";
import { jsx as jsx12 } from "react/jsx-runtime";
var SimpleLogo = (_a) => {
  var _b = _a, { label = "logo" } = _b, props = __objRest(_b, ["label"]);
  return /* @__PURE__ */ jsx12(AccessibilityWrap2, { label, children: /* @__PURE__ */ jsx12("svg", __spreadProps(__spreadValues({ viewBox: "0 0 67 79" }, props), { children: /* @__PURE__ */ jsx12(
    "path",
    {
      d: "M26.188 46.81v18.677c0 7.318 1.568 9.793 7.757 13.052v.23H0v-.23c4.716-3.043 5.621-5.856 5.621-13.281v-51.76C5.621 6.073 4.716 3.26 0 .216V0h36.877c17.77 0 29.458 8.548 29.458 23.412 0 14.85-11.688 23.398-29.336 23.398h-10.81zm4.5-2.922c10.567 0 15.621-8.67 15.621-20.476 0-11.808-5.053-20.599-15.62-20.599h-4.5v41.075h4.5z",
      fill: "currentColor"
    }
  ) })) });
};

// src/Media/SpeiceIcon.tsx
import { twMerge as twMerge6 } from "tailwind-merge";
import Icons4 from "@petrus/ui-icons";
import { jsx as jsx13 } from "react/jsx-runtime";
var SpeiceIcon = (_a) => {
  var _b = _a, { speice = "dog", className = "" } = _b, restProps = __objRest(_b, ["speice", "className"]);
  const props = __spreadValues({
    className: twMerge6("h-16", className)
  }, restProps);
  if (speice === "dog") {
    return /* @__PURE__ */ jsx13(Icons4.Dog, __spreadValues({}, props));
  }
  return /* @__PURE__ */ jsx13(Icons4.Cat, __spreadValues({}, props));
};

// src/Feedback/Callout.tsx
import { jsx as jsx14, jsxs as jsxs5 } from "react/jsx-runtime";
var Callout = ({ children, className }) => {
  return /* @__PURE__ */ jsxs5(Card, { className: classNames11("w-full p-5", className), children: [
    /* @__PURE__ */ jsx14("div", { className: "flex justify-center pb-5", children: /* @__PURE__ */ jsx14("div", { className: "bg-carmine rounded-full p-4 w-24", children: /* @__PURE__ */ jsx14(StatusIcon, { status: "info", className: "fill-white" }) }) }),
    children
  ] });
};

// src/Feedback/Notification.tsx
import classNames12 from "classnames";
import Icons5 from "@petrus/ui-icons";
import { jsx as jsx15, jsxs as jsxs6 } from "react/jsx-runtime";
var typeStyles2 = {
  success: "bg-success",
  danger: "bg-error",
  warning: "bg-warning",
  info: "bg-space"
};
var Notification = ({ children, onClose, type = "info", title }) => {
  return /* @__PURE__ */ jsxs6(
    Card,
    {
      role: "dialog",
      "aria-label": `${type} notification`,
      className: "flex w-full max-w-sm overflow-hidden min-h-12 text-space p-0",
      children: [
        /* @__PURE__ */ jsx15(
          "div",
          {
            className: classNames12(
              "flex flex-shrink-0 items-center justify-center w-12 min-w-12",
              typeStyles2[type]
            ),
            children: /* @__PURE__ */ jsx15(StatusIcon, { className: "w-6 fill-white", status: type })
          }
        ),
        /* @__PURE__ */ jsxs6("div", { className: "flex-1 p-3", children: [
          title && /* @__PURE__ */ jsx15(Text, { weight: "bold", className: "block mb-1", children: title }),
          /* @__PURE__ */ jsx15(Text, { as: "p", children })
        ] }),
        onClose && /* @__PURE__ */ jsx15(
          Button,
          {
            size: "tiny",
            look: "unstyled",
            onClick: onClose,
            className: "w-12 flex-shrink-0",
            "aria-label": "Close",
            children: /* @__PURE__ */ jsx15(Icons5.Cross, { className: "w-5 fill-space" })
          }
        )
      ]
    }
  );
};

// src/Feedback/Progress.tsx
import classNames13 from "classnames";
import * as RadixProgress from "@radix-ui/react-progress";
import { jsx as jsx16 } from "react/jsx-runtime";
var Progress = ({ progress = 0, className = "" }) => {
  return /* @__PURE__ */ jsx16(
    RadixProgress.Root,
    {
      value: progress,
      className: classNames13("h-2 w-full bg-white overflow-hidden rounded-lg", className),
      children: /* @__PURE__ */ jsx16(
        RadixProgress.Indicator,
        {
          style: { width: `${progress}%` },
          className: "h-full bg-carmine rounded-lg transition-all duration-700"
        }
      )
    }
  );
};

// src/Feedback/Spinner.tsx
import classNames14 from "classnames";
import Icons6 from "@petrus/ui-icons";
import { jsx as jsx17 } from "react/jsx-runtime";
var Spinner = (_a) => {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  return /* @__PURE__ */ jsx17(Icons6.Loader, __spreadValues({ className: classNames14("animate-spin", className) }, props));
};

// src/Actions/Btn/Btn.tsx
var buttonStyle = cva(
  [
    "inline-flex items-center rounded-md border border-transparent",
    "focus:outline-none focus:ring-2 focus:ring-offset-2"
  ],
  {
    variants: {
      intent: {
        primary: [
          "rounded-full border-none border-transparent stroke-white fill-white text-white bg-carmine",
          "hover:bg-carmine-dark active:bg-carmine-darkest",
          "focus:ring focus:ring-carmine-lightest",
          "w-full sm:w-fit"
        ],
        light: [
          "text-space fill-space bg-gray-100",
          "rounded-md",
          "focus:ring-pink",
          "hover:bg-gray-200"
        ],
        simple: ["text-carmine fill-carmine", "rounded-md", "focus:ring-pink", "w-full sm:w-fit"],
        unstyled: ["rounded-md", "focus:ring-pink"]
      },
      align: {
        start: "justify-start",
        center: "justify-center",
        end: "justify-end"
      },
      disabled: {
        true: "opacity-50 cursor-not-allowed"
      },
      full: {
        true: "w-full"
      },
      size: {
        xs: ["p-2 gap-2", "text-xs"],
        sm: ["px-3 py-2 gap-2", "text-sm"],
        md: ["px-6 py-3 gap-3", "text-sm"],
        lg: ["px-10 py-4 gap-4", "text-base"]
      }
    },
    defaultVariants: {
      intent: "primary",
      align: "center",
      size: "md",
      full: false,
      disabled: false
    }
  }
);
var Btn = React11.forwardRef(
  (_a, forwardedRef) => {
    var _b = _a, { className, disabled = false, loading = false, asChild = false, children } = _b, props = __objRest(_b, ["className", "disabled", "loading", "asChild", "children"]);
    const Comp = asChild ? Clone : "button";
    const isDisabled = !!disabled || loading;
    return React11.createElement(
      Comp,
      __spreadValues({
        ref: forwardedRef,
        className: twMerge7(
          buttonStyle(__spreadValues({
            disabled: isDisabled
          }, props)),
          className
        ),
        disabled: isDisabled,
        tabIndex: isDisabled ? -1 : 0
      }, props),
      loading ? React11.createElement(Spinner, { className: "h-4.5" }) : children
    );
  }
);

// src/Layout/Tabs/Tabs.tsx
import { jsx as jsx18, jsxs as jsxs7 } from "react/jsx-runtime";
var TabsContent = (_a) => {
  var _b = _a, { children } = _b, props = __objRest(_b, ["children"]);
  return /* @__PURE__ */ jsx18(RadixTabs.Content, __spreadProps(__spreadValues({}, props), { className: "flex flex-col gap-8 py-4", children }));
};
var TabsTrigger = ({ option, value }) => {
  const cs = useMemo3(
    () => classNames15(
      "whitespace-nowrap px-8 py-1 text-space",
      value === option.key ? "bg-white shadow" : "bg-transparent shadow-none"
    ),
    [value, option]
  );
  return /* @__PURE__ */ jsx18(Btn, { intent: "unstyled", className: cs, asChild: true, children: /* @__PURE__ */ jsx18(RadixTabs.Trigger, { value: option.key, children: option.title }) });
};
var TabsComponent = (_a) => {
  var _b = _a, { children, options = [] } = _b, props = __objRest(_b, ["children", "options"]);
  const [value, setValue] = useState(options[0].key);
  return /* @__PURE__ */ jsx18("div", { "data-testid": "petrus-tabs", children: /* @__PURE__ */ jsxs7(RadixTabs.Root, __spreadProps(__spreadValues({ value, onValueChange: setValue }, props), { children: [
    /* @__PURE__ */ jsx18(Card, { className: "bg-gray-100 p-1 w-min shadow-none", asChild: true, children: /* @__PURE__ */ jsx18(RadixTabs.List, { asChild: true, children: /* @__PURE__ */ jsx18("nav", { className: "flex space-x-2", children: options.map((option) => /* @__PURE__ */ jsx18(TabsTrigger, { option, value }, option.key)) }) }) }),
    /* @__PURE__ */ jsx18("div", { children })
  ] })) });
};
var Tabs = Object.assign(TabsComponent, {
  Content: TabsContent
});

// src/Layout/HR.tsx
import { createElement } from "react";
import { twMerge as twMerge8 } from "tailwind-merge";
var HR = (_a) => {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  return createElement("hr", __spreadValues({
    className: twMerge8("my-2 h-px border-0 bg-mauve-6 dark:bg-mauveDark-6", className)
  }, props));
};

// src/Actions/FullDialog.tsx
import { jsx as jsx19, jsxs as jsxs8 } from "react/jsx-runtime";
var DialogHeader = ({ className, title, withClosure = false }) => /* @__PURE__ */ jsxs8("div", { className: merge("flex pb-3 items-center justify-between", className), children: [
  /* @__PURE__ */ jsx19(RadixDialog.Title, { asChild: true, children: /* @__PURE__ */ jsx19(Heading, { as: "h3", className: "text-carmine", children: title }) }),
  withClosure && /* @__PURE__ */ jsx19(RadixDialog.Close, { asChild: true, children: /* @__PURE__ */ jsx19(Btn, { size: "xs", intent: "light", className: "rounded-full", children: /* @__PURE__ */ jsx19(Icons7.Cross, { className: "fill-space w-5" }) }) })
] });
var dialogStyle = cva2(["fixed z-50 w-full scale-100 opacity-100"], {
  variants: {
    position: {
      center: ["md:px-6"],
      right: ["h-screen"],
      left: ["h-screen"]
    }
  },
  defaultVariants: {
    position: "center"
  }
});
var DialogContent = React13.forwardRef(
  (_a, forwardedRef) => {
    var _b = _a, { size = "sm", className = "", position = "center", overlayClassName = "", children } = _b, props = __objRest(_b, ["size", "className", "position", "overlayClassName", "children"]);
    return /* @__PURE__ */ jsx19(RadixDialog.Portal, __spreadProps(__spreadValues({}, props), { children: /* @__PURE__ */ jsxs8(
      "div",
      {
        className: merge([
          "fixed inset-0 z-50 flex items-start justify-center",
          position === "center" && "justify-center sm:items-center",
          position === "right" && "justify-end",
          position === "left" && "justify-start"
        ]),
        children: [
          /* @__PURE__ */ jsx19(
            RadixDialog.Overlay,
            {
              forceMount: true,
              className: merge("fixed inset-0 z-40 bg-space/50", overlayClassName)
            }
          ),
          /* @__PURE__ */ jsx19(RadixDialog.Content, { asChild: true, ref: forwardedRef, children: /* @__PURE__ */ jsx19(Container, { size, className: dialogStyle({ position }), children: /* @__PURE__ */ jsx19(
            Card,
            {
              className: merge([
                "max-h-screen overflow-y-auto bg-blush text-carmine",
                "rounded-lg p-6 shadow-xl",
                ["left", "right"].includes(position || "") ? "h-full" : "",
                className
              ]),
              children
            }
          ) }) })
        ]
      }
    ) }));
  }
);
var FullDialog = Object.assign(RadixDialog.Root, {
  Trigger: RadixDialog.Trigger,
  Close: RadixDialog.Close,
  Content: DialogContent,
  Header: DialogHeader
});

// src/Actions/AccordionN/Accordion.tsx
import * as RadixAccordion from "@radix-ui/react-accordion";
import { jsx as jsx20 } from "react/jsx-runtime";
var AccordionRoot = (_a) => {
  var _b = _a, { children } = _b, props = __objRest(_b, ["children"]);
  return /* @__PURE__ */ jsx20(RadixAccordion.Root, __spreadProps(__spreadValues({}, props), { children }));
};
var AccordionItem = (_a) => {
  var _b = _a, { children } = _b, props = __objRest(_b, ["children"]);
  return /* @__PURE__ */ jsx20(RadixAccordion.Item, __spreadProps(__spreadValues({}, props), { children }));
};
var AccordionHeader = (_a) => {
  var _b = _a, { children } = _b, props = __objRest(_b, ["children"]);
  return /* @__PURE__ */ jsx20(RadixAccordion.Header, { children: /* @__PURE__ */ jsx20(RadixAccordion.Trigger, __spreadProps(__spreadValues({}, props), { children })) });
};
var AccordionContent = (_a) => {
  var _b = _a, { children } = _b, props = __objRest(_b, ["children"]);
  return /* @__PURE__ */ jsx20(RadixAccordion.Content, __spreadProps(__spreadValues({}, props), { children }));
};
var Accordion = Object.assign(AccordionRoot, {
  Header: AccordionHeader,
  Item: AccordionItem,
  Content: AccordionContent
});

// src/Actions/DropdownMenu/DropdownMenu.tsx
import * as RadixDropdownMenu from "@radix-ui/react-dropdown-menu";
import { jsx as jsx21 } from "react/jsx-runtime";
var DropdownMenuRoot = (props) => {
  return /* @__PURE__ */ jsx21(RadixDropdownMenu.Root, __spreadValues({}, props));
};
var DropdownTrigger = (props) => {
  return /* @__PURE__ */ jsx21(RadixDropdownMenu.Trigger, __spreadValues({}, props));
};
var DropdownContent = (_a) => {
  var _b = _a, {
    children
  } = _b, props = __objRest(_b, [
    "children"
  ]);
  return /* @__PURE__ */ jsx21(RadixDropdownMenu.Content, __spreadProps(__spreadValues({ asChild: true, className: "m-2 z-50" }, props), { children: /* @__PURE__ */ jsx21(Card, { children }) }));
};
var DropdownItem = (props) => {
  return /* @__PURE__ */ jsx21(RadixDropdownMenu.Item, __spreadValues({}, props));
};
var DropdownMenu = Object.assign(DropdownMenuRoot, {
  Trigger: DropdownTrigger,
  Content: DropdownContent,
  Item: DropdownItem
});

// src/Actions/Accordion.tsx
import * as RadixAccordion2 from "@radix-ui/react-accordion";
import { jsx as jsx22 } from "react/jsx-runtime";
var AccordionRoot2 = (_a) => {
  var _b = _a, { children } = _b, props = __objRest(_b, ["children"]);
  return /* @__PURE__ */ jsx22(RadixAccordion2.Root, __spreadProps(__spreadValues({}, props), { children }));
};
var AccordionItem2 = (_a) => {
  var _b = _a, { children } = _b, props = __objRest(_b, ["children"]);
  return /* @__PURE__ */ jsx22(RadixAccordion2.Item, __spreadProps(__spreadValues({}, props), { children }));
};
var AccordionHeader2 = (_a) => {
  var _b = _a, { children } = _b, props = __objRest(_b, ["children"]);
  return /* @__PURE__ */ jsx22(RadixAccordion2.Header, { children: /* @__PURE__ */ jsx22(RadixAccordion2.Trigger, __spreadProps(__spreadValues({}, props), { children })) });
};
var AccordionContent2 = (_a) => {
  var _b = _a, { children } = _b, props = __objRest(_b, ["children"]);
  return /* @__PURE__ */ jsx22(RadixAccordion2.Content, __spreadProps(__spreadValues({}, props), { children }));
};

// src/Actions/AccordionCard.tsx
import classNames16 from "classnames";
import { jsx as jsx23, jsxs as jsxs9 } from "react/jsx-runtime";
var theme4 = {
  item: "bg-white rounded-lg",
  header: {
    trigger: "w-full",
    wrap: "flex p-4 w-full items-center justify-between hover:shadow-sm",
    text: "text-space",
    iconWrap: "bg-pink p-2 rounded-full relative",
    iconBadgeWrap: "w-5 h-5 rounded-full bg-carmine text-white absolute top-[-8px] right-[-6px]",
    icon: "w-5 fill-white"
  },
  content: "py-3 px-4 border-t-1 border-pink"
};
var AccordionCard = (_a) => {
  var _b = _a, { heading, Icon, badgeInfo, className, children } = _b, props = __objRest(_b, ["heading", "Icon", "badgeInfo", "className", "children"]);
  return /* @__PURE__ */ jsxs9(AccordionItem2, __spreadProps(__spreadValues({ className: theme4.item }, props), { children: [
    /* @__PURE__ */ jsx23(AccordionHeader2, { className: theme4.header.trigger, children: /* @__PURE__ */ jsxs9("div", { className: theme4.header.wrap, children: [
      /* @__PURE__ */ jsx23(Heading, { as: "h4", className: theme4.header.text, children: heading }),
      Icon && /* @__PURE__ */ jsxs9("div", { className: theme4.header.iconWrap, children: [
        badgeInfo && /* @__PURE__ */ jsx23("div", { className: theme4.header.iconBadgeWrap, children: /* @__PURE__ */ jsx23(Text, { size: "small", children: badgeInfo }) }),
        /* @__PURE__ */ jsx23(Icon, { className: theme4.header.icon })
      ] })
    ] }) }),
    /* @__PURE__ */ jsx23(AccordionContent2, { className: classNames16(theme4.content, className), children })
  ] }));
};

// src/Actions/Button.tsx
import React14, { useMemo as useMemo4 } from "react";
import classNames17 from "classnames";
import { twMerge as twMerge9 } from "tailwind-merge";
var lookStyles = {
  primary: {
    base: "rounded-full border-none border-transparent text-white bg-carmine hover:bg-darkcarmine active:bg-darkcarmine focus:ring focus:ring-pink",
    disabled: "opacity-50 cursor-not-allowed hover:bg-carmine",
    icon: "fill-white"
  },
  secondary: {
    base: "rounded-full border-none border-transparent text-space bg-pink hover:bg-blush active:bg-blush focus:ring focus:ring-carmine",
    disabled: "opacity-50 cursor-not-allowed hover:bg-carmine",
    icon: "fill-space"
  },
  outline: {
    base: "rounded-full border border-carmine text-carmine hover:bg-blush hover:border-transparent focus:ring focus:ring-pink",
    disabled: "opacity-50 cursor-not-allowed hover:bg-carmine",
    icon: "fill-carmine"
  },
  dotted: {
    base: "border-dashed border-2 border-carmine",
    disabled: "",
    icon: ""
  },
  focus: {
    base: "focus:ring focus:ring-pink",
    disabled: "opacity-50 cursor-not-allowed",
    icon: ""
  },
  unstyled: {
    base: "",
    disabled: "opacity-50 cursor-not-allowed",
    icon: ""
  }
};
var sizeStyles2 = {
  big: "px-10 py-4",
  base: "px-8 py-3",
  small: "px-7 py-2",
  tiny: "p-2 w-fit",
  micro: "w-fit"
};
var Button = React14.forwardRef(
  (_a, forwardedRef) => {
    var _b = _a, {
      look = "primary",
      size = "base",
      className,
      disabled = false,
      loading = false,
      block = false,
      asChild = false,
      children
    } = _b, props = __objRest(_b, [
      "look",
      "size",
      "className",
      "disabled",
      "loading",
      "block",
      "asChild",
      "children"
    ]);
    const Comp = asChild ? Clone : "button";
    const isDisabled = !!disabled || loading;
    const { cs, iconCs } = useMemo4(() => {
      const style = lookStyles[look];
      return {
        cs: twMerge9(
          classNames17(
            "w-full md:w-fit focus:outline-none text-base transition-colors duration-150 inline-flex items-center justify-center",
            sizeStyles2[size],
            style.base,
            block && "w-full md:w-full",
            disabled ? style.disabled : "hover:cursor-pointer"
          ),
          className
        ),
        iconCs: classNames17("h-4.5", style.icon)
      };
    }, [look, size, block, disabled, className]);
    return React14.createElement(
      Comp,
      __spreadValues({
        ref: forwardedRef,
        className: cs,
        disabled: isDisabled,
        tabIndex: isDisabled ? -1 : 0
      }, props),
      loading ? React14.createElement(Spinner, { className: iconCs }) : children
    );
  }
);

// src/Actions/CardButton.tsx
import React15 from "react";
import classNames18 from "classnames";
import Icons8 from "@petrus/ui-icons";
import { jsx as jsx24 } from "react/jsx-runtime";
var CardButton = React15.forwardRef(
  (_a, ref) => {
    var _b = _a, { children, className, disabled = false, selected = false, useArrow = false } = _b, props = __objRest(_b, ["children", "className", "disabled", "selected", "useArrow"]);
    return React15.createElement(
      "button",
      __spreadValues({
        ref,
        className: classNames18(
          "relative group flex flex-col p-3 focus:outline-none focus:ring focus:ring-pink rounded-lg hover:shadow-sm transition-all duration-150 ",
          disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer",
          selected ? "bg-pink text-space" : "bg-white text-space",
          className
        ),
        disabled,
        tabIndex: disabled ? -1 : 0
      }, props),
      children,
      /* @__PURE__ */ jsx24("div", { className: "absolute flex justify-center items-center inset-y-0 right-6 group-hover:right-5 transition-all duration-150", children: useArrow && /* @__PURE__ */ jsx24(Icons8.DogBoneArrow, { className: "w-5 h-5 rotate-180" }) })
    );
  }
);

// src/Actions/Dialog.tsx
import * as RadixDialog2 from "@radix-ui/react-dialog";
import { twMerge as twMerge10 } from "tailwind-merge";
import Icons9 from "@petrus/ui-icons";
import { jsx as jsx25, jsxs as jsxs10 } from "react/jsx-runtime";
var Dialog = ({
  isOpen,
  onIsOpenChange,
  size = "sm",
  title = "",
  className = "",
  overlayClassName = "",
  withHeader = true,
  children
}) => {
  return /* @__PURE__ */ jsx25(RadixDialog2.Root, { open: isOpen, onOpenChange: onIsOpenChange, children: /* @__PURE__ */ jsxs10(RadixDialog2.Portal, { children: [
    /* @__PURE__ */ jsx25(
      RadixDialog2.Overlay,
      {
        forceMount: true,
        className: twMerge10("fixed inset-0 z-40 bg-space/50", overlayClassName)
      }
    ),
    /* @__PURE__ */ jsx25(RadixDialog2.Content, { asChild: true, children: /* @__PURE__ */ jsx25(
      Container,
      {
        size,
        className: "fixed top-[50%] left-[50%] z-50 w-full -translate-x-[50%] -translate-y-[50%]",
        children: /* @__PURE__ */ jsxs10(
          Card,
          {
            className: twMerge10(
              "rounded-lg p-6 shadow-xl max-h-screen overflow-y-auto bg-blush text-carmine",
              className
            ),
            children: [
              withHeader && /* @__PURE__ */ jsxs10("div", { className: "flex pb-3 items-center justify-between", children: [
                /* @__PURE__ */ jsx25(RadixDialog2.Title, { asChild: true, children: /* @__PURE__ */ jsx25(Heading, { as: "h3", className: "", children: title }) }),
                /* @__PURE__ */ jsx25(RadixDialog2.Close, { asChild: true, children: /* @__PURE__ */ jsx25(Btn, { size: "xs", intent: "light", className: "rounded-full", children: /* @__PURE__ */ jsx25(Icons9.Cross, { className: "fill-space w-5" }) }) })
              ] }),
              children
            ]
          }
        )
      }
    ) })
  ] }) });
};

// src/Actions/Popover.tsx
import * as RadixPopover from "@radix-ui/react-popover";
import { twMerge as twMerge11 } from "tailwind-merge";
import { jsx as jsx26 } from "react/jsx-runtime";
var Content8 = (_a) => {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  return /* @__PURE__ */ jsx26(RadixPopover.Portal, { children: /* @__PURE__ */ jsx26(
    RadixPopover.Content,
    __spreadValues({
      className: twMerge11(
        "bg-white shadow-black/40",
        "z-50 rounded-md shadow outline-none",
        className
      ),
      asChild: true
    }, props)
  ) });
};
var PopoverTrigger = RadixPopover.Trigger;
var PopoverRoot = RadixPopover.Root;
var Popover = Object.assign(PopoverRoot, {
  Trigger: PopoverTrigger,
  Content: Content8
});

// src/Actions/TextCopy.tsx
import { useClipboard } from "@petrus/ui-hooks";
import Icons10 from "@petrus/ui-icons";
import { jsx as jsx27, jsxs as jsxs11 } from "react/jsx-runtime";
var theme5 = {
  base: "flex justify-between",
  input: "text-space grow py-1 border-transparent focus:outline-none active:border-none w-full rounded-md rounded-r-none",
  button: "p-2 w-fit rounded-md rounded-l-none hover:bg-pink active:bg-pink",
  icon: "w-4 fill-space"
};
var CopiedIcon = ({ hasCopied, className }) => {
  if (hasCopied) {
    return /* @__PURE__ */ jsx27(Icons10.Check, { className });
  }
  return /* @__PURE__ */ jsx27(Icons10.Copy, { className });
};
var TextCopy = ({ text }) => {
  const { copy, hasCopied } = useClipboard(text);
  return /* @__PURE__ */ jsxs11("div", { className: theme5.base, children: [
    /* @__PURE__ */ jsx27("input", { type: "text", value: text, className: theme5.input }),
    /* @__PURE__ */ jsxs11(Button, { className: theme5.button, onClick: copy, look: "secondary", children: [
      /* @__PURE__ */ jsxs11(GhostText, { children: [
        "Copy ",
        text,
        " to clipboard"
      ] }),
      /* @__PURE__ */ jsx27(CopiedIcon, { hasCopied, className: theme5.icon })
    ] })
  ] });
};

// src/Fields/index.tsx
import { FormProvider as FormProvider2, useForm as useForm2 } from "react-hook-form";

// src/Fields/Checkbox.tsx
import { Controller, useFormContext } from "react-hook-form";
import { Fragment as Fragment2, jsx as jsx28, jsxs as jsxs12 } from "react/jsx-runtime";
var theme6 = {
  wrap: "flex align-middle",
  label: "ml-2"
};
var Checkbox = ({ id, label, disabled = false }) => {
  const { control, register } = useFormContext();
  return /* @__PURE__ */ jsx28("div", { className: theme6.wrap, children: /* @__PURE__ */ jsx28(
    Controller,
    {
      name: id,
      control,
      render: ({ field }) => /* @__PURE__ */ jsxs12(Fragment2, { children: [
        /* @__PURE__ */ jsx28(
          CheckboxIndicator,
          __spreadProps(__spreadValues({}, register(id)), {
            id,
            value: field.value,
            disabled,
            onChange: (value) => field.onChange(value)
          })
        ),
        /* @__PURE__ */ jsx28(Label, { label, htmlFor: id, className: theme6.label, clickable: true })
      ] })
    }
  ) });
};

// src/Fields/DateInput.tsx
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import classNames19 from "classnames";
import { useFormContext as useFormContext2, Controller as Controller2 } from "react-hook-form";

// src/Fields/FieldWrapper.tsx
import { jsx as jsx29, jsxs as jsxs13 } from "react/jsx-runtime";
var FieldWrapper = ({
  htmlFor,
  label,
  caption,
  error,
  children,
  required = false,
  ghostLabel = false
}) => {
  return /* @__PURE__ */ jsxs13("div", { className: "space-y-1 grow", children: [
    /* @__PURE__ */ jsx29(Label, { htmlFor, label, hidden: ghostLabel, required }),
    children,
    /* @__PURE__ */ jsx29(HelperText, { text: caption }),
    error && error.length > 0 && /* @__PURE__ */ jsx29(Text, { className: "block text-error", children: error })
  ] });
};

// src/Fields/DateInput.tsx
import { jsx as jsx30 } from "react/jsx-runtime";
dayjs.extend(customParseFormat);
var theme7 = {
  base: "my-2",
  input: "my-1 border border-transparent focus:ring-space border-space block w-full rounded-md placeholder-gray-300",
  disabled: "opacity-70",
  error: "focus:ring-error border-error focus:border-error"
};
var dateFormat = "DD-MM-YY";
var transformDateValue = (e) => {
  var _a, _b, _c;
  const v = String((_a = e.target) == null ? void 0 : _a.value);
  if (((_b = e.nativeEvent) == null ? void 0 : _b.inputType) === "") {
    return v;
  }
  if (((_c = e.nativeEvent) == null ? void 0 : _c.inputType) === "insertText") {
    const newVale2 = [2, 5].includes(v.length) ? `${v}-` : v;
    return newVale2;
  }
  const newVale = [2, 5].includes(v.length) ? v.slice(0, -1) : v;
  return newVale;
};
var DateInput = ({ id, label, disabled = false, required = false, error }) => {
  const { control } = useFormContext2();
  return /* @__PURE__ */ jsx30(
    Controller2,
    {
      control,
      name: id,
      render: ({ field: { onChange, onBlur, value, name, ref } }) => /* @__PURE__ */ jsx30(FieldWrapper, { htmlFor: name, label, caption: error, required, children: /* @__PURE__ */ jsx30(
        "input",
        {
          ref,
          onChange: (e) => onChange(transformDateValue(e)),
          onBlur,
          type: "text",
          name,
          id: name,
          disabled,
          className: classNames19(theme7.input, disabled && theme7.disabled, error && theme7.error),
          placeholder: dateFormat,
          "aria-describedby": name,
          value: value || ""
        }
      ) })
    }
  );
};

// src/Fields/Form.tsx
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { twMerge as twMerge12 } from "tailwind-merge";
import { jsx as jsx31 } from "react/jsx-runtime";
var Form = (_a) => {
  var _b = _a, {
    onSubmit,
    formProps,
    children
  } = _b, useFormProps = __objRest(_b, [
    "onSubmit",
    "formProps",
    "children"
  ]);
  const methods = useForm(__spreadValues({}, useFormProps));
  useEffect(() => {
    if (useFormProps.values) {
      methods.reset(useFormProps.values);
    }
  }, [useFormProps.values, methods]);
  return /* @__PURE__ */ jsx31(FormProvider, __spreadProps(__spreadValues({}, methods), { children: /* @__PURE__ */ jsx31(
    "form",
    __spreadProps(__spreadValues({
      onSubmit: methods.handleSubmit(onSubmit),
      noValidate: true
    }, formProps), {
      className: twMerge12("space-y-4", formProps == null ? void 0 : formProps.className),
      children
    })
  ) }));
};

// src/Fields/Input.tsx
import classNames20 from "classnames";
import { useFormContext as useFormContext3 } from "react-hook-form";
import { jsx as jsx32 } from "react/jsx-runtime";
var theme8 = {
  base: "my-2",
  input: "my-1 border border-transparent focus:ring-space block w-full rounded-md text-space placeholder-gray-300",
  disabled: "bg-gray-50 opacity-50 cursor-not-allowed	",
  error: "focus:ring-error border-error focus:border-error"
};
var Input = (_a) => {
  var _b = _a, {
    id,
    label,
    caption,
    step = "0",
    type = "text",
    placeholder = "",
    disabled = false,
    ghostLabel = false,
    required = false,
    error,
    autoComplete,
    inputMode
  } = _b, props = __objRest(_b, [
    "id",
    "label",
    "caption",
    "step",
    "type",
    "placeholder",
    "disabled",
    "ghostLabel",
    "required",
    "error",
    "autoComplete",
    "inputMode"
  ]);
  const { register } = useFormContext3();
  const handleSetValue = (value) => {
    if (type !== "number") {
      return value;
    }
    if (!value) {
      return null;
    }
    if (step === "0") {
      return parseInt(value, 10);
    }
    return parseFloat(value);
  };
  return /* @__PURE__ */ jsx32(
    FieldWrapper,
    {
      htmlFor: id,
      label,
      caption,
      error,
      ghostLabel,
      required,
      children: /* @__PURE__ */ jsx32(
        "input",
        __spreadValues(__spreadProps(__spreadValues({}, register(id, {
          setValueAs: (value) => handleSetValue(value),
          required
        })), {
          type,
          name: id,
          id,
          step,
          disabled,
          className: classNames20(theme8.input, disabled && theme8.disabled, error && theme8.error),
          placeholder,
          "aria-describedby": id,
          autoComplete,
          inputMode
        }), props)
      )
    }
  );
};

// src/Fields/RadioCardGroup.tsx
import classNames21 from "classnames";
import { Controller as Controller3, useFormContext as useFormContext4 } from "react-hook-form";
import * as RadixRadioGroup from "@radix-ui/react-radio-group";
import { Label as RadixLabel2 } from "@radix-ui/react-label";
import { jsx as jsx33, jsxs as jsxs14 } from "react/jsx-runtime";
var theme9 = {
  base: "",
  orientation: {
    vertical: "block",
    horizontal: "flex justify-around"
  },
  group: {
    base: "border-1 p-2 relative flex cursor-pointer text-space",
    border: {
      left: "rounded-r-none",
      right: "rounded-l-none"
    },
    orientation: {
      vertical: "w-full mb-3 last-of-type:mb-0",
      horizontal: "flex-1 first-of-type:ml-0 last-of-type:mr-0"
    }
  },
  input: {
    indicator: "absolute inset-0 border-2 border-pink/60 bg-pink bg-opacity-60 rounded-lg z-10",
    label: "z-20 w-full"
  }
};
var RadioCardGroup = ({
  id,
  label,
  options = [],
  orientation = "vertical",
  required = false,
  className,
  error,
  render
}) => {
  const { control, register } = useFormContext4();
  return /* @__PURE__ */ jsx33(FieldWrapper, { htmlFor: id, label, error, required, children: /* @__PURE__ */ jsx33(
    Controller3,
    {
      name: id,
      control,
      render: ({ field }) => /* @__PURE__ */ jsx33(
        RadixRadioGroup.Root,
        __spreadProps(__spreadValues({}, register(id)), {
          id,
          name: id,
          defaultValue: field.value,
          onValueChange: field.onChange,
          orientation,
          className: classNames21(theme9.base, theme9.orientation[orientation]),
          children: /* @__PURE__ */ jsx33(
            For,
            {
              each: options,
              render: (option, i) => /* @__PURE__ */ jsx33(RadixRadioGroup.Item, { value: option, id: option, asChild: true, children: /* @__PURE__ */ jsxs14(
                Card,
                {
                  className: classNames21(
                    theme9.group.base,
                    theme9.group.orientation[orientation],
                    className,
                    theme9.group.border[i % 2 === 0 ? "left" : "right"]
                  ),
                  style: { WebkitAppearance: "none" },
                  children: [
                    /* @__PURE__ */ jsx33(RadixRadioGroup.Indicator, { children: /* @__PURE__ */ jsx33(
                      "span",
                      {
                        className: classNames21(
                          theme9.input.indicator,
                          theme9.group.border[i % 2 === 0 ? "left" : "right"]
                        )
                      }
                    ) }),
                    /* @__PURE__ */ jsx33(RadixLabel2, { htmlFor: option, className: theme9.input.label, children: render(option) })
                  ]
                }
              ) }, option)
            }
          )
        })
      )
    }
  ) });
};

// src/Fields/RadioGroup.tsx
import classNames22 from "classnames";
import { Controller as Controller4, useFormContext as useFormContext5 } from "react-hook-form";
import * as RadixRadioGroup2 from "@radix-ui/react-radio-group";
import Icons11 from "@petrus/ui-icons";
import { jsx as jsx34, jsxs as jsxs15 } from "react/jsx-runtime";
var theme10 = {
  base: "w-full",
  orientation: {
    vertical: "block",
    horizontal: "flex"
  },
  group: {
    base: "flex block items-center mt-1",
    orientation: {
      vertical: "mb-4 last-of-type:mb-0",
      horizontal: "mr-6"
    }
  },
  input: "w-6 h-6 rounded-full mr-2 flex focus:outline-none bg-white focus:ring focus:ring-pink",
  indicator: "w-full h-full flex justify-center place-content-center",
  icon: "fill-space w-4 h-4 m-1"
};
var RadioGroupItem = ({ label, value, orientation = "vertical" }) => /* @__PURE__ */ jsxs15("div", { className: classNames22(theme10.group.base, theme10.group.orientation[orientation]), children: [
  /* @__PURE__ */ jsx34(RadixRadioGroup2.Item, { value, id: value, className: theme10.input, children: /* @__PURE__ */ jsx34(RadixRadioGroup2.Indicator, { className: theme10.indicator, children: /* @__PURE__ */ jsx34(Icons11.Circle, { className: theme10.icon }) }) }),
  /* @__PURE__ */ jsx34(Label, { htmlFor: value, label, clickable: true })
] });
var RadioGroup = ({ id, label, error, options = [], orientation = "vertical" }) => {
  const { control, register } = useFormContext5();
  return /* @__PURE__ */ jsx34(FieldWrapper, { htmlFor: id, label, error, children: /* @__PURE__ */ jsx34(
    Controller4,
    {
      name: id,
      control,
      render: ({ field }) => /* @__PURE__ */ jsx34(
        RadixRadioGroup2.Root,
        __spreadProps(__spreadValues({}, register(id)), {
          id,
          name: id,
          onValueChange: field.onChange,
          orientation,
          className: classNames22(theme10.base, theme10.orientation[orientation]),
          children: /* @__PURE__ */ jsx34(
            For,
            {
              each: options,
              render: (option) => /* @__PURE__ */ jsx34(RadioGroupItem, __spreadProps(__spreadValues({}, option), { orientation }), option.value)
            }
          )
        })
      )
    }
  ) });
};

// src/Fields/Select.tsx
import classNames23 from "classnames";
import { Controller as Controller5, useFormContext as useFormContext6 } from "react-hook-form";
import { jsx as jsx35 } from "react/jsx-runtime";
var theme11 = {
  input: "block w-full rounded-md border-1 focus:ring-space focus:border-space border-space py-2 pl-3 pr-10",
  disabled: "opacity-70",
  error: "focus:ring-error border-error focus:border-error"
};
var Select = ({ id, label, options, caption, error, disabled = false }) => {
  const { control } = useFormContext6();
  return /* @__PURE__ */ jsx35(FieldWrapper, { htmlFor: id, label, caption, error, children: /* @__PURE__ */ jsx35(
    Controller5,
    {
      name: id,
      control,
      render: ({ field }) => /* @__PURE__ */ jsx35(
        "select",
        __spreadProps(__spreadValues({}, field), {
          className: classNames23(theme11.input, disabled && theme11.disabled, error && theme11.error),
          children: options.map((option) => /* @__PURE__ */ jsx35("option", { value: option, children: option }, option))
        })
      )
    }
  ) });
};

// src/Fields/Textarea.tsx
import classNames24 from "classnames";
import { useFormContext as useFormContext7 } from "react-hook-form";
import { jsx as jsx36 } from "react/jsx-runtime";
var Textarea = ({ id, label, caption, error, placeholder = "", disabled = false }) => {
  const { register } = useFormContext7();
  return /* @__PURE__ */ jsx36(FieldWrapper, { htmlFor: id, label, caption, error, children: /* @__PURE__ */ jsx36(
    "textarea",
    __spreadProps(__spreadValues({}, register(id)), {
      rows: 3,
      name: id,
      id,
      className: classNames24(
        "resize-y my-1 border-1 focus:ring-space border-transparent focus:border-space block w-full rounded-md placeholder-gray-300 text-space",
        disabled && "opacity-70",
        error && "focus:ring-error border-error focus:border-error"
      ),
      placeholder,
      "aria-describedby": id
    })
  ) });
};
export {
  Accordion,
  AccordionCard,
  AccordionContent2 as AccordionContent,
  AccordionHeader2 as AccordionHeader,
  AccordionItem2 as AccordionItem,
  AccordionRoot2 as AccordionRoot,
  Alert,
  Badge,
  Banner,
  Btn,
  Button,
  Callout,
  Card,
  CardButton,
  Center,
  Checkbox,
  CheckboxIndicator,
  Container,
  DateInput,
  Dialog,
  DropdownMenu,
  Empty,
  FieldWrapper,
  For,
  Form,
  FormProvider2 as FormProvider,
  FullDialog,
  GenderIcon,
  GhostText,
  HR,
  HamburgerMenu,
  Heading,
  HelperText,
  Input,
  Label,
  Logo,
  Notification,
  Popover,
  Progress,
  RadioCardGroup,
  RadioGroup,
  ScrollArea,
  Select,
  SimpleLogo,
  SpeiceIcon,
  Spinner,
  StatusIcon,
  Tabs,
  Text,
  TextCopy,
  Textarea,
  useForm2 as useForm
};
